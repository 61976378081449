import type { BuildTimeDEXConfiguration } from "@internal/dex-framework";

export const studioTitle = "VistaPrint | Expert Studio";
export const trackingTenant: BuildTimeDEXConfiguration["trackingTenant"] = "vistaprint";
export const getHelpCenterUrl: BuildTimeDEXConfiguration["getHelpCenterUrl"] = async locale => {
    // requested via https://vistaprint.atlassian.net/wiki/spaces/CDST/pages/3306685504/GCP+Comprehensive+Migration+Guide#%3Aorder%3A--%5BinlineExtension%5DPre-Migration-checklist
    const gcpsApiKey = "FAqYG5sHRS7kCZaEFlShg8OHp055PVWTjvI6zYnf";
    const url = `https://global-channel-provider-service.care.vpsvc.com/v3/pages?tenant=vistaprint&locale=${locale}&page=help_center_page`;
    try {
        const response = await fetch(url, { headers: { "x-api-key": gcpsApiKey } });
        const data = await response.json();
        return data?.path;
    } catch (e) {
        // eslint-disable-next-line no-console -- yeah we can log stuff during build
        console.log(`error getting CARE contact for ${locale}`);
    }
    return undefined;
};
export const getLogoAndHomePage: BuildTimeDEXConfiguration["getLogoAndHomePage"] = async locale => {
    try {
        const response = await fetch(
            `https://bookends.cdn.vpsvc.com/v3/vistaprint/${locale}/header/slim?requestor=studio`
        );
        const data = await response.json();
        return {
            logo: data?.base.inlineSecondaryLogo,
            url: data?.base.homeUrl
        };
    } catch (e) {
        // eslint-disable-next-line no-console -- yeah we can log stuff during build
        console.log(`error getting header/footer for ${locale}`);
        throw e;
    }
};
